<template>
  <div class="schedule container">
    <LeftMenus :menus="menus" :menusHandle="menusHandle" :active="active" />
    <div class="mainContent">
      <DateTab
        :active.sync="dateTab"
        :menuActive="active"
        :dateData="dateDataModal"
        :scheduleModal="scheduleModal"
      />
      <div class="scheduleWrap hid-scrollbar">
        <ScheduleItem
          v-for="(item, key) in scheduleModal"
          :key="key"
          :data="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenus from "@/components/LeftMenus";
import { getMatchList } from "@/service/Schedule";
import DateTab from "./components/DateTab.vue";
import ScheduleItem from "./components/ScheduleItem.vue";
import { Format } from "@/utils/fn";
import { getWeek } from "@/utils/time";
import { sphList, matchList, stageGroup } from "@/utils/filedList";
import { mapState } from "vuex";

export default {
  name: "Schedule",
  components: {
    LeftMenus,
    DateTab,
    ScheduleItem,
  },
  data() {
    return {
      menus: [],
      active: false,
      dateTab: "all",
      dateData: [],
      scheduleData: [],
      fetchTimer: null,
    };
  },
  created() {
    this.getMatchList(true);
  },
  destroyed() {
    clearTimeout(this.fetchTimer);
    this.fetchTimer = null;
  },
  watch: {
    active() {
      this.dateTab = "all";
    },
  },
  computed: {
    scheduleModal() {
      return this.scheduleData.filter((item) => {
        return (
          (this.activeGroupValueModal.indexOf(item.groupValue) > -1 ||
            item.groupValue == this.active ||
            this.active == "all") &&
          (this.dateTab == "all" || item.dateTime == this.dateTab)
        );
      });
    },
    activeGroupValueModal() {
      let _active = this.active ? this.active + "" : "";
      if (_active == "all") {
        return [];
      }
      let idx = _active.indexOf("all");
      if (idx > -1) {
        let activeId = _active.replace("all", "");
        return stageGroup[activeId].group;
      }
      return [];
    },
    dateDataModal() {
      return this.dateData.filter((item) => {
        let hasGroupVal = false;
        if (this.activeGroupValueModal.length) {
          hasGroupVal = this.activeGroupValueModal.find((groupVal) => {
            return item.groupValueArr.indexOf(groupVal) > -1;
          });
        }
        return (
          hasGroupVal ||
          item.groupValueArr.indexOf(this.active) > -1 ||
          this.active == "all"
        );
      });
    },
    languageType() {
      return this.common.languageType;
    },
    ...mapState(["common"]),
  },
  methods: {
    menusHandle(val) {
      this.active = val;
    },

    getMatchList(firstLoad) {
      getMatchList({ languageType: this.languageType }).then((res) => {
        if (res.success) {
          this.buildData(res.data, firstLoad);
          this.fetchTimer = setTimeout(() => {
            this.getMatchList();
          }, 3000);
        }
      });
    },
    buildDate(btm) {
      let dateTime = Format(new Date(btm), "yyyy-MM-dd");
      let formatWeek = getWeek(btm)[this.getLocal()];
      let dateFormat = Format(
        new Date(btm),
        `M${this.$t("home.tabM")}d${this.$t("home.tabD")}`
      );
      return {
        dateTime,
        formatWeek,
        dateFormat,
        btm,
      };
    },
    buildGroup(sph) {
      if (sphList[sph]) {
        return {
          groupValue: sph,
          groupText: sphList[sph][this.getLocal()],
          groupSort: sphList[sph].sort || sphList[sph].EN,
        };
      }
      if (matchList[sph]) {
        return {
          groupValue: sph,
          groupText: matchList[sph][this.getLocal()],
          groupSort: matchList[sph].groupSort,
        };
      }
      if (!sphList[sph] && !matchList[sph]) return {};
    },
    buildData(data, firstLoad) {
      let scheduleTemp = [];
      let dateDataTemp = [];
      let menusTemp = [];
      let _firstData = false
      data.forEach((item) => {
        let itemTemp = { ...item };
        // 時間格式
        const timeObj = this.buildDate(item.btm);
        let hasOne = dateDataTemp.find((dateItem) => {
          if (dateItem.dateTime == timeObj.dateTime) {
            dateItem.groupValueArr.push(item.sph);
            return true;
          }
        });
        // 分組
        let groupTemp = this.buildGroup(item.sph);

        if (!hasOne) {
          timeObj.groupValueArr = [item.sph];
          timeObj.groupText = groupTemp.groupText;
          timeObj.groupValue = groupTemp.groupValue;
          dateDataTemp.push(timeObj);
        }
        let hasOneGroup = menusTemp.find(
          (item) => item.groupValue == groupTemp.groupValue
        );

        let stageVal = [];
        if (sphList[item.sph]) {
          stageVal = Object.values(stageGroup).filter((item) => {
            return item.group.includes(itemTemp.sph);
          });
        }
        if (stageVal.length) {
          const hasStage = menusTemp.find((menu) => {
            if (menu.groupArr) {
              return menu.groupArr.find((stage) => {
                return stageVal[0].group.includes(stage.groupValue);
              });
            }
            return menu.groupValue == groupTemp.groupValue;
          });
          if (!hasStage) {
            menusTemp.push({
              groupValue: stageVal[0].id,
              groupText: stageVal[0][this.getLocal()],
              groupSort: stageVal[0].sort,
              groupArr: [
                {
                  groupValue: "all" + stageVal[0].id,
                  groupText: this.$t("home.All"),
                  groupSort: "A",
                },
                groupTemp,
              ],
            });
          } else {
            let hasTem = hasStage.groupArr.find((stage) => {
              return stage.groupValue == groupTemp.groupValue;
            });
            if (!hasTem) {
              hasStage.groupArr.push(groupTemp);
            }
          }
        } else if (!hasOneGroup) {
          menusTemp.push(groupTemp);
        }
        itemTemp = { ...itemTemp, ...timeObj, ...groupTemp };
        scheduleTemp.push(itemTemp);
      });
      this.dateData = dateDataTemp.sort(
        (curr, next) => new Date(curr.btm) - new Date(next.btm)
      );
      menusTemp.forEach((item) => {
        if (item.groupArr) {
          item.groupArr = item.groupArr.sort((a, b) =>
            (a.groupSort + "").localeCompare(b.groupSort + "")
          );
        }
      });
      this.menus = menusTemp.sort((a, b) =>
        (a.groupSort + "").localeCompare(b.groupSort + "")
      );
      this.menus.unshift({
        groupValue: "all",
        groupText: this.$t("home.All"),
        groupSort: "A",
      });
      if (this.active === false) {
        this.active = "all";
      }
      this.scheduleData = scheduleTemp.sort(
        (curr, next) => curr.btm - next.btm
      );
      this.scheduleData.forEach(item => {
        // 第一次進入默認選未開賽第一場
        if((item.st === undefined || item.st === 4) && !_firstData && firstLoad){
          _firstData = item;
        }
      })
      if(_firstData){
        this.active = _firstData.groupValue;
        this.$nextTick(() => {
          this.dateTab = _firstData.dateTime;
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  display: flex;
  height: 100%;
  .mainContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .scheduleWrap {
    overflow: auto;
    flex: 1;
    border: 1px solid $border-color;
    margin-top: 18px;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    background: #fff;
  }
}
.dark {
  .schedule .scheduleWrap {
    background: #151618;
  }
}
</style>
