<template>
  <el-tooltip v-model="show" :manual="true" popper-class="customMsg" :hide-after="3000" effect="dark" :content="data.st === 0 ?$t('home.Finish') :$t('home.nodata')" placement="top">
  <div class="scheduleItem" @click="backFn">
    <div class="main">
      <div class="group" v-if="data.groupText">
        <span>{{ data.groupText }}</span>
      </div>
      <SgsLeft :data="data" />

      <SgsCenter :data="data" />
      <div class="sgsLeft">
        <!-- <span v-for="(item, index) in sgsData" :key="index">{{ item }}</span> -->
        <span v-if="htData.length">{{
          $t("home.HT") + ":" + htData.join("-")
        }}</span>
        <span v-if="cornerData.length">{{
          $t("home.Corner") + ":" + cornerData.join("-")
        }}</span>
      </div>
    </div>
    <div class="overtime" v-if="getOvertime.length">
      <span v-for="(item, key) in getOvertime" :key="key">
        {{
          sgsPeList[item.pe][getLocal()] +
          "[" +
          item.sc[0] +
          "-" +
          item.sc[1] +
          "]"
        }}
        {{ key + 1 != getOvertime.length ? " , " : "" }}
      </span>
    </div>
  </div>
  </el-tooltip>
</template>

<script>
import SgsLeft from "./SgsLeft";
import SgsCenter from "./SgsCenter";
import { sgsPeList, MATCH_PERIOD_2_PERIOD_MAP } from "@/utils/filedList";
import { mapState } from "vuex";
import db from '@/utils/localStorage'
import { Tooltip } from 'element-ui';

export default {
  name: "ScheduleItem",
  props: ["data"],
  components: { SgsLeft, SgsCenter, [Tooltip.name]: Tooltip },
  data() {
    return {
      sgsPeList,
      show: false
    };
  },
  computed: {
    nowPe() {
      return MATCH_PERIOD_2_PERIOD_MAP[this.data?.clk?.pe];
    },
    cornerData() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1001 || item.pe == 1013) && item.tyg == 6) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },
    qtData() {
      if (!this.data.sgs) return [];
      let mapTemp = [];
      this.data.sgs.forEach((item) => {
        let isHt = item.pe == 3009 && item.tyg == 5;
        if (isHt) {
          mapTemp = item.sc;
        }
      });
      return mapTemp;
    },
    htData(){
      if(!this.data.sgs) return []
      let mapTemp = [];
      // let clkPe = this.data.clk ? this.data.clk.pe : 0
      this.data.sgs.forEach((item) => {
        // let isHt =
        //   clkPe >= 1006 && clkPe <= 1009
        //     ? item.pe == 1004 && item.tyg == 5
        //     : item.pe == 1002 && item.tyg == 5;
        let isHt = item.pe == 1002 && item.tyg == 5;
        if (isHt) {
          mapTemp = item.sc
        }
      });
      return mapTemp
    },
    overtime() {
      if (!this.data.sgs) return [];
      const _data = this.data.sgs.reduce((prev, item) => {
        if ((item.pe == 1006 || item.pe == 1013) && item.tyg == 5) {
          prev = prev.length > 0 ? prev : [0, 0];
          prev[0] = prev[0] + item.sc[0];
          prev[1] = prev[1] + item.sc[1];
        }
        return prev;
      }, []);
      return _data;
    },
    getOvertime() {
      // if (this.data.st != 0) return [];
      let clkPe = this.data.clk ? this.data.clk.pe : 0;

      if (this.data.sgs) {
        return this.data.sgs.filter((item) => {
          let is90Min =
            clkPe >= 1006 && clkPe <= 1009 && item.pe == 1001 && item.tyg == 5;
          return (
            (item.pe == 1006 && item.tyg == 5) ||
            (item.pe == 1013 && item.tyg == 5) ||
            is90Min
          );
        });
      }

      return [];
    },
    backurl() {
      return this.common.backUrl;
    },
    ...mapState(["common"]),
  },
  methods: {
    // backFn() {
    //   if (!this.data.id || !this.data.st)
    //     return;
    //   const _url =
    //     this.backurl +
    //     `/index.html#/?type=100&leagueId=10658`
    //   console.log(_url);
    //   location.href = _url;
    // },
    backFn() {
      if (
        !this.data.id ||
        this.data.st === undefined ||
        this.data.st === null ||
        this.data.st === 0 ||
        !this.backurl
      ){
        this.show = true
        setTimeout(() => {
          this.show = false
        }, 2000)
        return;
      }
      const languageType = db.get("languageType", "")
      const _token = this.common.token
      const _nickname = this.common.nickname
      const _color = this.common.theme

      
      let params = '?type=101';
      if(languageType){
        params = params + '&language=' + languageType
      }
      if(_token){
        params = params + '&token=' + _token
      }
      if(_nickname){
        params = params + '&nickname=' + _nickname
      }
      if(_color){
        params = params + '&color=' + _color
      }
      const _url =
        this.backurl +
        // 'http://localhost:8080'+
        `/index.html#/detail/${this.data.id}/1${params}`;
      window.open(_url);
    },
  },
};
</script>
<style lang="scss">
.customMsg{
  min-width: auto;
  padding: 0 30px;
  line-height: 30px;
  height: 30px;
  background: #131A3E!important;
  border: none;
  border-radius: 5px;
    color: #fff;
    .popper__arrow{
      display: none;
    }
}
</style>
<style lang="scss" scoped>
.scheduleItem {
  border-bottom: 1px solid $border-color;
  color: $text-color;

  cursor: pointer;
  position: relative;
  margin-top: 8px;
  padding-left: 15px;
  .sgsLeft {
    width: 208px;
    // margin-right: 12px;
    font-size: 12px;
    display: flex;
    // align-items: flex-end;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: flex-end;
    text-align: right;
    margin-left: 11px;
  padding-right: 20px;
    span {
      margin-right: 8px;
      &:first-child{
        flex:1;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .active {
      color: #f90;
      font-weight: 500;
    }
  }
  .main {
    width: 100%;
    display: flex;
    align-items: center;
    height: 55px;
    position: relative;
  }
  .overtime {
    display: flex;
    // width: 100%;
    margin-left: -15px;
    justify-content: center;
    align-items: center;
    // height: 20px;
    color: $text-color;
    font-size: 12px;
    // padding: 0 125px 0 181px;
    background: #FFD2D4;
    border-radius: 0px 0px 10px 10px;
    span {
      margin-right: 8px;
    }
  }

  .group {
    text-align: center;
    background: $group-bg-color;
    line-height: 14px;
    padding: 0px 6px;
    gap: 10px;
    font-size: 10px;
    position: absolute;
    height: 14px;
    left: -0.5px;
    top: calc(50% - 14px / 2 - 20.5px);
    // color: #fff;
    border-radius: 0px 0px 8px 0px;
    span {
      display: inline-block;
      transform: scale(0.83);
    }
  }

  .right {
    display: flex;
    height: 55px;
    align-items: center;
    padding: 5px;
    i {
      cursor: pointer;
      color: $text-color-light;
      &:hover {
        color: #ca0a44;
      }
    }
  }
}
.dark{ 
  .scheduleItem .overtime{
    background: #470D10;
    color: #D2D4D9;
  }
}
</style>
